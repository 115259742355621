<template>
    <div id="index">
        <div class="container">
            <h1>app list</h1>
            <el-row type="flex" justify="center">
                <el-col :span="5">
                    <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-app">添加app</button>
                </el-col>
                <el-col :span="5">
                    <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-white-id">添加ip白名单</button>
                </el-col>
            </el-row>
            

            <v-sort-tables  class_name="table table-hover"
                            :columns_list="columns_list"
                            @sort="sortBy"
                           >
                <tbody>
                    <tr v-for="app_item in app_list" :key="app_item.id">
                        <th scope="row">{{ app_item.id }}</th>
                        <td>
                            <template v-if="app_item.sys_platform == 0">
                                <span>android</span>
                            </template>
                            <template v-else>
                                <span>ios</span>
                            </template>
                        </td>
                        <td>{{ app_item.name }}</td>
                        <td>{{ app_item.package_name }}</td>
                        <td>
                            <template v-if="app_item.exclusive == 1">
                                <span style="color:red;">独占登录</span>
                            </template>
                            <template v-else>
                                <span style="color:green;">共享登录</span>
                            </template>
                        </td>
                        <td>
                            <template v-if="app_item.disable == 1">
                                <span style="color:red;">禁用</span>
                            </template>
                            <template v-else>
                                <span style="color:green;">启用</span>
                            </template>
                        </td>
                        <td>{{ app_item.create_dt|ts_2_dt }}</td>
                        <td>
                            <button class="btn btn-success btn-sm" @click="showDetailModal(app_item.package_name, app_item.sys_platform)" style="margin-right:5px;">详情</button>
                            <button class="btn btn-primary btn-sm" @click="showEditModal(app_item.id)" style="margin-right:5px;">编辑</button>
                            <!-- <button class="btn btn-danger btn-sm" @click="deleteApp(app_item.package_name)">删除</button> -->
                        </td>
                    </tr>
                </tbody>

            </v-sort-tables>
        </div>



        <div class="modal fade" id="add-app" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">添加</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">平台</label>
                        <div class="col-sm-10" style="text-align:left;">
                            <div style="padding:0.375rem 0">
                                <div class="form-check form-check-inline"  >
                                    <input class="form-check-input" type="radio" name="sys_platform" id="sys_platform_1"  value="0" v-model="add_sys_platform">
                                    <label class="form-check-label" for="sys_platform_1">android</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="sys_platform" id="sys_platform_2" value="1" v-model="add_sys_platform">
                                    <label class="form-check-label" for="sys_platform_2">ios</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">app名称</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="add_name">
                        </div>
                    </div>
                    

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">包名</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="add_package_name">
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" class="btn btn-primary" @click="addApp">提交</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="add-white-id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">添加ip白名单</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">ip</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="add_white_ip">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" class="btn btn-primary" @click="addWhiteIp">提交</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="detail-app" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ current_package_name }} <small>总注册人数：{{ register_total }}</small></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-sm-5">
                                 <el-date-picker
                                    v-model="start_dt"
                                    type="date"
                                    placeholder="开始日期"
                                    style="width:140px"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    value-format="yyyy/MM/dd"
                                    @change="changeValue">
                                </el-date-picker>
                                ~
                                <el-date-picker
                                    v-model="end_dt"
                                    type="date"
                                    placeholder="结束日期"
                                    style="width:140px"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    value-format="yyyy/MM/dd"
                                    @change="changeValue">
                                </el-date-picker>
                            </div>
                            <div class="col-sm-5">
                                <div class="index-time-selector-list">
                                    <div class="index-time-selector-item" :class="{'selected': select_date_str == 'today'}" @click="selectedDate('today')">今日</div>
                                    <div class="index-time-selector-item" :class="{'selected': select_date_str == 'yesterday'}" @click="selectedDate('yesterday')">昨日</div>
                                    <div class="index-time-selector-item" :class="{'selected': select_date_str == 'thisMonth'}" @click="selectedDate('thisMonth')">本月</div>
                                    <div class="index-time-selector-item" :class="{'selected': select_date_str == 'lastMonth'}" @click="selectedDate('lastMonth')">上月</div>
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <button class="btn btn-primary btn-sm" @click="queryAppDetail">查询</button>
                            </div>
                        </div>
                        <el-tabs v-model="active_name" @tab-click="handleClick">
                            <el-tab-pane label="注册列表" name="reigster">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <td>id
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="userSortBy('id')"></i>
                                            </td>
                                            <td>phone_num
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="userSortBy('phone_num')"></i>
                                            </td>
                                            <td>create_ts
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="userSortBy('create_ts')"></i>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user_item, index) in show_user_list" :key="index">
                                            <td>{{ user_item.id }}</td>
                                            <td>{{ user_item.phone_num }}</td>
                                            <td>{{ user_item.create_ts|ts_2_dt }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <el-pagination
                                    background
                                    layout="total, prev, pager, next"
                                    :total="user_list.length"
                                    @current-change="handleCurrentChange">
                                </el-pagination>
                                
                            </el-tab-pane>
                            <el-tab-pane label="支付列表" name="pay">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <td>user_id
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="paySortBy('user_id')"></i>
                                            </td>
                                            <td>phone_num
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="paySortBy('phone_num')"></i>
                                            </td>
                                            <td>buy_ts
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="paySortBy('buy_ts')"></i>
                                            </td>
                                            <td>recharge_type
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="paySortBy('recharge_type')"></i>
                                            </td>
                                            <td>pay_channel
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="paySortBy('pay_channel')"></i>
                                            </td>
                                            <td>recharge_price
                                                <i  class="bi bi-arrow-down-up sort-icon"
                                                    @click="paySortBy('recharge_price')"></i>
                                            </td>
                                            <td>pay_detail</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(pay_item, index) in show_pay_list" :key="index">
                                            <td>{{ pay_item.user_id }}</td>
                                            <td>{{ pay_item.phone_num }}</td>
                                            <td>{{ pay_item.buy_ts|ts_2_dt }}</td>
                                            <td>{{ pay_item.recharge_type }}</td>
                                            <td>{{ pay_item.pay_channel }}</td>
                                            <td>{{ pay_item.recharge_price }}</td>
                                            <td>
                                                <a v-if="pay_item.pay_id != 0" href="#" @click="showPayDetail(pay_item.pay_id)">详情</a>
                                                <span v-else>-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div style="float:right; color:red;">
                                    总计：{{ total_price|floatformat(2) }}
                                </div>

                                <el-pagination
                                    background
                                    layout="total, prev, pager, next"
                                    :total="pay_list.length"
                                    @current-change="handleCurrentPayListChange">
                                </el-pagination>
                                
                            </el-tab-pane>
                        </el-tabs>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="edit-app" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">编辑</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">平台</label>
                        <div class="col-sm-10" style="text-align:left;">

                            <template v-if="edit_sys_platform == 0">
                                <input type="text" class="form-control" value="android" disabled>
                            </template>
                            <template v-else>
                                <input type="text" class="form-control" value="ios" disabled>
                            </template>
                        </div>
                    </div>


                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">app名称</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="edit_name">
                        </div>
                    </div>
                    

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">包名</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="edit_package_name">
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">独占登录</label>
                        <div class="col-sm-10" style="text-align:left;">
                            <div style="padding:0.375rem 0">
                                <div class="form-check form-check-inline"  >
                                    <input class="form-check-input" type="radio" name="is_exclusive" id="inlineRadio1" value="1" v-model="edit_exclusive">
                                    <label class="form-check-label" for="inlineRadio1">独占登录</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="is_exclusive" id="inlineRadio2" value="0" v-model="edit_exclusive">
                                    <label class="form-check-label" for="inlineRadio2">共享登录</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">是否禁用</label>
                        <div class="col-sm-10" style="text-align:left;">
                            <div style="padding:0.375rem 0">
                                <div class="form-check form-check-inline"  >
                                    <input class="form-check-input" type="radio" name="is_disable" id="inlineRadio3" value="1" v-model="edit_disable">
                                    <label class="form-check-label" for="inlineRadio3">是</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="is_disable" id="inlineRadio4" value="0" v-model="edit_disable">
                                    <label class="form-check-label" for="inlineRadio4">否</label>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" class="btn btn-primary" @click="editApp">提交</button>
                </div>
                </div>
            </div>
        </div>
    
        <div class="position-fixed top-0 start-50 translate-middle-x" style="z-index: 11">
            <div id="test-toast" class="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">操作成功</div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>

        <div class="modal fade" id="pay-detail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">支付详情</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">充值天数</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.recharge_days" disabled>
                        </div>
                    </div>
                    

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">充值价格</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.recharge_price" disabled>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">充值类型</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.recharge_type" disabled>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">支付渠道</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.pay_channel_name" disabled>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">订单号</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.trade_no" disabled>
                        </div>
                    </div>


                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">购买时间</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.buy_ts" disabled>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">手机号</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.phone_num" disabled>
                        </div>
                    </div>


                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">微信openid</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.wechat_openid" disabled>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">微信nickname</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="pay_detail.wechat_nickname" disabled>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">remark</label>
                        <div class="col-sm-10">
                            <textarea type="text" rows="5" class="form-control" v-model="pay_detail.remark" disabled></textarea>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
                </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import api from '@/common/js/axios/api'
import utils from '@/common/js/utils'
import Cookies from 'js-cookie'

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"

import Modal from 'bootstrap/js/dist/modal'
import Toast from 'bootstrap/js/dist/toast'
import $ from 'jquery'

import VSortTables from '@/components/v-sort-tables'

export default {
    name:'index',
    components: { VSortTables },
    data() {
        return {
            toast: null,
            addModal: null,
            addWhiteIpModal: null,
            editModal: null,
            detailModal: null,
            payDetailModal: null,

            app_list: [],
            username: '',
            password: '',

            add_name: '',
            add_package_name: '',
            add_sys_platform: -1,

            add_white_ip: '',

            edit_id: 0,
            edit_sys_platform: -1,
            edit_name: '',
            edit_package_name: '',
            edit_exclusive: 0,
            edit_disable: 0,

            columns_list: [
                { key:'id', name:'id', sort: true },
                { key:'sys_platform', name:'平台', sort: true },
                { key:'name', name:'app 名称', sort: true },
                { key:'package_name', name:'包名', sort: true },
                { key:'exclusive', name:'独占登录', sort: true },
                { key:'disable', name:'是否禁用', sort: true },
                { key:'create_dt', name:'创建时间', sort: true },
                { key:'handle', name:'操作', sort: false },
            ],
            sort_desc: true,

            current_sys_platform: -1,
            current_package_name: "",
            register_total: 0,
            start_dt: "",
            end_dt: "",
            select_date_str: "",
            
            active_name: "reigster",


            page_count: 10,      // 每页显示的数量

            user_list: [],
            show_user_list: [],
            user_list_page_index: 1,   // 当前页


            pay_list: [],
            show_pay_list: [],
            pay_list_page_index: 1,

            sort_state: true,

            total_price: 0,

            pay_detail: {
                recharge_days: '',
                recharge_price: '',
                recharge_type: '',
                pay_channel_name: '',
                trade_no: '',
                buy_ts: '',
                phone_num: '',
                wechat_openid: '',
                wechat_nickname: '',
                remark: ''
            },
        }  
    },
    methods: {
        initPage() {
            this.toast = new Toast($('#test-toast'), {
                                    animation: true,
                                    autohide: true,
                                    delay: 1500
                                })

            this.addModal = new Modal($('#add-app'))
            this.editModal = new Modal($('#edit-app'))
            this.detailModal = new Modal($('#detail-app'))
            this.addWhiteIpModal = new Modal($('#add-white-id'))
            this.payDetailModal = new Modal($('#pay-detail'))
            
        },
        goIndex() {
            api.goIndex().then(data => {
                this.app_list = data.app_list
            })
        },
        addApp() {
            if (this.add_sys_platform == -1) {
                alert('请选择平台')
                return
            }

            if(this.add_name.length == 0) {
                alert('app名称不可为空')
                return
            }
            if(this.add_package_name.length == 0) {
                alert('包名不可为空')
                return
            }
            api.addApp(this.add_package_name, this.add_name, this.add_sys_platform).then(data => {
                console.log(data)
                this.addModal.hide()
                this.goIndex();
            })
        },
        showEditModal(id) {
            this.app_list.forEach(element => {
                if(element.id === id) {
                    this.edit_id = element.id
                    this.edit_sys_platform = element.sys_platform
                    this.edit_name = element.name
                    this.edit_package_name = element.package_name
                    this.edit_exclusive = element.exclusive
                    this.edit_disable = element.disable
                }
            });
            this.editModal.show()
        },
        editApp() {
            let id = this.edit_id
            let sys_platform = this.edit_sys_platform
            let package_name = this.edit_package_name
            let name = this.edit_name
            let exclusive = this.edit_exclusive
            let disable = this.edit_disable
            if(name.length == 0) {
                alert('请输入app名称')
                return
            }

            if(package_name.length == 0) {
                alert('请输入包名')
                return
            }

            api.editApp(id, package_name, name, exclusive, disable, sys_platform).then(data => {
                console.log(data)
                this.toast.show()
                this.editModal.hide()
                this.goIndex()
            })
        },
        // deleteApp(package_name) {
        //     if(!confirm('确定删除此app')) {
        //         return
        //     }
        //     api.removeApp(package_name).then(data => {
        //         console.log(data)
        //         this.toast.show()
        //         this.goIndex()
        //     })
        // },
        sortBy(column_item) {
            this.sort_desc = !this.sort_desc
            
            this.app_list = this.app_list.sort((a, b) => {

                let sort_a = a[column_item.key]
                let sort_b = b[column_item.key]

                if(this.sort_desc) {
                    if(typeof(sort_a) == 'string' && typeof(sort_b) == 'string') {
                        return a[column_item.key].localeCompare(b[column_item.key])
                    }
                    return a[column_item.key] - b[column_item.key]
                } else {
                    if(typeof(sort_a) == 'string' && typeof(sort_b) == 'string') {
                        return b[column_item.key].localeCompare(a[column_item.key])
                    }
                    return b[column_item.key] - a[column_item.key]
                }
            })
        },

        changeValue() {
            let start_dt = this.start_dt
            let end_dt = this.end_dt
            let today = this.todayPicker();
            let prevDay = this.prevDay();
            let thisMonth = this.thisMonth();
            let prevMonth = this.prevMonth();
            if(start_dt == today.start_dt && end_dt == today.end_dt) {
                 this.select_date_str = "today"
                 return
            }
            if(start_dt == prevDay.start_dt && end_dt == prevDay.end_dt) {
                 this.select_date_str = "yesterday"
                 return
            }
            if(start_dt == thisMonth.start_dt && end_dt == thisMonth.end_dt) {
                 this.select_date_str = "thisMonth"
                 return
            }
            if(start_dt == prevMonth.start_dt && end_dt == prevMonth.end_dt) {
                 this.select_date_str = "lastMonth"
                 return
            }
            this.select_date_str = ""
        },

        showDetailModal(package_name, sys_platform) {
            this.active_name = "reigster"
            this.current_sys_platform = sys_platform
            this.current_package_name = package_name
            this.selectedDate("today");
            this.detailModal.show();
        },

        queryAppDetail() {
            api.queryAppDetail(this.current_package_name, this.start_dt, this.end_dt, this.current_sys_platform).then(data => {

                this.user_list = data.user_list.sort((a,b) => {
                    return b.create_ts - a.create_ts;
                })
                
                this.pay_list = data.pay_list.sort((a, b) => {
                    return b.buy_ts - a.buy_ts;
                })
                this.total_price = 0;
                this.pay_list.forEach(item => {
                    this.total_price += item.recharge_price
                })

                this.register_total = data.register_count

                this.handleCurrentChange(1)
                this.handleCurrentPayListChange(1)
            })
        },

        selectedDate(date) {
            this.select_date_str = date
            var str = null;
            switch (this.select_date_str) {
                case "today":
                    str = this.todayPicker();
                    break;
                case "yesterday":
                    str = this.prevDay();
                    break;
                case "thisMonth":
                    str = this.thisMonth();
                    break;
                case "lastMonth":
                    str = this.prevMonth();
                    break;
                default:
                    str = this.todayPicker();
                    break;
            }
            this.start_dt = str.start_dt;
            this.end_dt = str.end_dt;
            this.queryAppDetail();
        },

        handleClick() {

        },

        handleCurrentChange(val) {
            this.user_list_page_index = val
            this.handleUserListPageData()
        },
        handleUserListPageData() {
            let index = (this.user_list_page_index - 1) * this.page_count
            this.show_user_list = this.user_list.slice(index, index + this.page_count)
        },

        handleCurrentPayListChange(val) {
            this.pay_list_page_index = val
            this.handlePayListPageData()

        },
        handlePayListPageData() {
            let index = (this.pay_list_page_index - 1) * this.page_count
            this.show_pay_list = this.pay_list.slice(index, index + this.page_count)
        },


        userSortBy(column_item) {
            this.user_list = this.mSort(this.user_list, column_item)
            this.handleUserListPageData()
        },

        paySortBy(column_item) {
            this.pay_list = this.mSort(this.pay_list, column_item)
            this.handlePayListPageData()
        },

        mSort(data_list, column_item) {
            this.sort_state = !this.sort_state
            return data_list.sort((a, b) => {
                let sort_a = a[column_item]
                let sort_b = b[column_item]

                if(this.sort_state) {
                    if(typeof(sort_a) == 'string' && typeof(sort_b) == 'string') {
                        return sort_a.localeCompare(sort_b)
                    }
                    return sort_a - sort_b
                } else {
                    if(typeof(sort_a) == 'string' && typeof(sort_b) == 'string') {
                        return sort_b.localeCompare(sort_a)
                    }
                    return sort_b - sort_a
                }

            })
        },
        
        todayPicker() {
            var today = new Date();
            return {
                'start_dt': this.dateToString(today),
                'end_dt': this.dateToString(today),
            };
        },
        prevDay() {
            var date = new Date();
            date.setDate(date.getDate()-1)
            return {
                'start_dt': this.dateToString(date),
                'end_dt': this.dateToString(date),
            }
        },
        thisMonth() {
            var today = new Date();
            var firstDay = this.firstDayOfMonth(today);
            return {
                'start_dt': this.dateToString(firstDay),
                'end_dt': this.dateToString(today),
            };
        },
        prevMonth() {
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
            return {
                'start_dt': this.dateToString(firstDay),
                'end_dt': this.dateToString(lastDay),
            };
        },
        firstDayOfMonth(dt) {
            return new Date(dt.getFullYear(), dt.getMonth(), 1);
        },
        lastDayOfMonth(dt) {
            return new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
        },
        dateToString(dt) {
            let mm = dt.getMonth() + 1; 
            let dd = dt.getDate();
            return [
                dt.getFullYear(),
                (mm > 9 ? '' : '0') + mm,
                (dd > 9 ? '' : '0') + dd
            ].join('/');
        },
        addWhiteIp() {
            if(!this.isValidIP(this.add_white_ip)) {
                alert("请输入正确格式的ip地址");
                return
            }
            api.addTempIpWhitelist(this.add_white_ip).then(data => {
                if (data.status_code === 200) {
                    this.toast.show()
                    this.addWhiteIpModal.hide()
                }
            })
        },
        isValidIP(ip) {
            var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
            return reg.test(ip);
        },
        showPayDetail(pay_id) {
            api.queryPayDetail(pay_id).then(data => {
                this.pay_detail.recharge_days = data.recharge_days
                this.pay_detail.recharge_price = data.recharge_price
                this.pay_detail.recharge_type = data.recharge_type
                this.pay_detail.pay_channel_name = data.pay_channel_name
                this.pay_detail.trade_no = data.trade_no
                this.pay_detail.buy_ts = utils.ts_2_dt(data.buy_ts)
                this.pay_detail.phone_num = data.phone_num
                this.pay_detail.wechat_openid = data.wechat_openid
                this.pay_detail.wechat_nickname = data.wechat_nickname
                this.pay_detail.remark = data.remark

                this.payDetailModal.show();
            })
        }
    },
    created() {
        this.username = Cookies.get('name')
        this.password = Cookies.get('pwd')
        this.goIndex();

        this.$nextTick(() => {
            this.initPage();
        })
        
    }
}
</script>

<style scoped>
.index-time-selector-list {
  display: inline-block;
  margin-right: 25px;
}

.index-time-selector-item {
  float: left;
  font-size: 12px;
  border: 1px solid #BFBFBF;
  border-right: none;
  padding: 5px 20px;
  background: #ECF5FE;
  cursor: pointer;
}

.index-time-selector-item.selected {
  color: white;
  background: #0DA0FE;
}

.index-time-selector-item:last-child {
  border-right: 1px solid #BFBFBF;
}
</style>